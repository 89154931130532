<template>
  <div class="noticeBox">
    <van-row>
      <van-col span="24">
        <div class="process-row">
          <div class="process-box">
            <div class="box-title">月度</div>
            <div class="box-process">
              <van-circle
                :value="cityData.stationPct"
                :speed="100"
                :color="cityData.stationPct >= 0 || !cityData.stationPct ? '#839BFB' : 'red'"
                :text="cityData.stationPct ? cityData.stationPct + '%' : 0 + '%'"
                layer-color="#e2e2e2"
                size="1rem"
              />
              <div class="box-data">
                <div class="data-item">基数：{{ cityData.monthBase }}</div>
                <div class="data-item">目标：{{ cityData.monthTarget }}</div>
              </div>
            </div>

          </div>
          <div class="process-box">
            <div class="box-title">季度</div>
            <div class="box-process">
              <van-circle
                :value="cityData.quarterStationPct"
                :speed="100"
                :color="cityData.quarterStationPct >= 0 || !cityData.quarterStationPct ? '#839BFB' : 'red'"
                :text="cityData.quarterStationPct ? cityData.quarterStationPct + '%' : 0 + '%'"
                layer-color="#e2e2e2"
                size="1rem"
              />
              <div class="box-data">
                <div class="data-item">基数：{{ cityData.quarterBase }}</div>
                <div class="data-item">目标：{{ cityData.quarterTarget }}</div>
              </div>
            </div>
          </div>
        </div>
        <!--新建站点-->
        <div class="static-row" v-if="tabType === 'NewlyStation'">
          <div class="static-item">
            <div class="static-title">已加盟</div>
            <div class="static-data">
              <div class="static-data-item">
                新增：{{ cityData.newlyJoinedStation }}
              </div>
              <div class="static-data-item">
                累计：{{ cityData.totalJoinedStation }}
              </div>
            </div>
          </div>
          <div class="static-item">
            <div class="static-title">已签约</div>
            <div class="static-data">
              <div class="static-data-item">
                新增：{{ cityData.newlySignedStation }}
              </div>
              <div class="static-data-item">
                累计：{{ cityData.totalSignedStation }}
              </div>
            </div>
          </div>
          <div class="static-item">
            <div class="static-title">新建站点</div>
            <div class="static-data">
              <div class="static-data-item">
                新增：{{ cityData.quarterNewlyStation }}
              </div>
              <div class="static-data-item">
                累计：{{ cityData.totalNewlyStation }}
              </div>
            </div>
          </div>
        </div>
        <!--有效站点-->
        <div
          class="flex justify_content_space_around"
          v-if="tabType === 'ActiveStation'"
        >
          <div class="flex align_items_center">
            <div class="font-15">新增有效：</div>
            <div class="font-15">{{ cityData.newlyActiveStation }}</div>
          </div>
          <div class="flex align_items_center">
            <div class="font-15">累计有效：</div>
            <div class="font-15">{{ cityData.totalActiveStation }}</div>
          </div>
        </div>
        <!--双百站点-->
        <div
          class="flex justify_content_space_around"
          v-if="tabType === 'DoubleHundred'"
        >
          <div class="flex align_items_center">
            <div class="font-15">新增双百：</div>
            <div class="font-15">{{ cityData.newlyDoubleHundredStation }}</div>
          </div>
          <div class="flex align_items_center">
            <div class="font-15">累计双百：</div>
            <div class="font-15">{{ cityData.totalDoubleHundredStation }}</div>
          </div>
        </div>
        <!--时点余额-->
        <div
          class="flex justify_content_space_around"
          v-if="tabType === 'PointBalance'"
        >
          <div class="flex align_items_center">
            <div class="font-15">新增余额：</div>
            <div class="font-15">{{ cityData.newlyPointBalance }}</div>
          </div>
          <div class="flex align_items_center">
            <div class="font-15">累计余额：</div>
            <div class="font-15">{{ cityData.totalPointBalance }}</div>
          </div>
        </div>
        <!--上线-->
        <div
                class="flex justify_content_space_around"
                v-if="tabType === 'onlineStation'"
        >
          <div class="flex align_items_center">
            <div class="font-15">新增上线：</div>
            <div class="font-15">{{ cityData.newlyOnlineCount }}</div>
          </div>
          <div class="flex align_items_center">
            <div class="font-15">累计上线：</div>
            <div class="font-15">{{ cityData.onlineCount }}</div>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "notTitleGoals",
  props: {
    cityData: Object,
    tabType: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
@import "../style/goals.less";
.noticeBox {
  background: #ffffff;
  margin-bottom: 0.1rem;
}
</style>
