<template>
  <div>
    <div class="plate" v-for="(item, index) in dataList" :key="index">
      <div class="content-title">
        <div class="header-text">
          <span v-if="rankingType==='PERSON'">{{item.deptName}}</span>
          {{ item.objName }}
      </div>
        <van-image
          v-if="item.stationRanking < 4"
          width="30"
          height="30"
          :src="
            item.stationRanking === 1
              ? gold
              : item.stationRanking === 2
              ? silver
              : item.stationRanking === 3
              ? copper
              : ''
          "
          round
        />
        <div v-if="item.stationRanking > 3" :style="bgBox" class="background-image">{{item.stationRanking}}</div>
      </div>


      <van-row>
        <van-col span="24">
          <div class="process-row">
            <div class="process-box">
              <div class="box-title">月度</div>
              <div class="box-process">
                <van-circle
                  :value="item.stationPct"
                  :speed="100"
                  :color="item.stationPct >= 0 || !item.stationPct ? '#839BFB' : 'red'"
                  :text="item.stationPct ? item.stationPct + '%' : 0 + '%'"
                  layer-color="#e2e2e2"
                  size="1rem"
                />
                <div class="box-data">
                  <div class="data-item">基数：{{ item.monthBase }}</div>
                  <div class="data-item">目标：{{ item.monthTarget }}</div>
                </div>
              </div>
            </div>
            <div class="process-box">
              <div class="box-title">季度</div>
              <div class="box-process">
                <van-circle
                  :value="item.quarterStationPct"
                  :speed="100"
                  :color="item.quarterStationPct >= 0 || !item.quarterStationPct ? '#839BFB' : 'red'"
                  :text="item.quarterStationPct ? item.quarterStationPct + '%' : 0 + '%' "
                  layer-color="#e2e2e2"
                  size="1rem"
                />
                <div class="box-data">
                  <div class="data-item">基数：{{ item.quarterBase }}</div>
                  <div class="data-item">目标：{{ item.quarterTarget }}</div>
                </div>
              </div>
            </div>
          </div>
          <!--新建站点-->
          <div class="static-row" v-if="tabType === 'NewlyStation'">
            <div class="static-item">
              <div class="static-title">已加盟</div>
              <div class="static-data">
                <div class="static-data-item">
                  新增：{{ item.newlyJoinedStation }}
                </div>
                <div class="static-data-item">
                  累计：{{ item.totalJoinedStation }}
                </div>
              </div>
            </div>
            <div class="static-item">
              <div class="static-title">已签约</div>
              <div class="static-data">
                <div class="static-data-item">
                  新增：{{ item.newlySignedStation }}
                </div>
                <div class="static-data-item">
                  累计：{{ item.totalSignedStation }}
                </div>
              </div>
            </div>
            <div class="static-item">
              <div class="static-title">新建站点</div>
              <div class="static-data">
                <div class="static-data-item">
                  新增：{{ item.quarterNewlyStation }}
                </div>
                <div class="static-data-item">
                  累计：{{ item.totalNewlyStation }}
                </div>
              </div>
            </div>
          </div>
          <!--有效站点-->
          <div
            class="flex justify_content_space_around"
            v-if="tabType === 'ActiveStation'"
          >
            <div class="flex align_items_center">
              <div class="font-15">新增有效：</div>
              <div class="font-15">{{ item.newlyActiveStation }}</div>
            </div>
            <div class="flex align_items_center">
              <div class="font-15">累计有效：</div>
              <div class="font-15">{{ item.totalActiveStation }}</div>
            </div>
          </div>
          <!--双百站点-->
          <div
            class="flex justify_content_space_around"
            v-if="tabType === 'DoubleHundred'"
          >
            <div class="flex align_items_center">
              <div class="font-15">新增双百：</div>
              <div class="font-15">{{ item.newlyDoubleHundredStation }}</div>
            </div>
            <div class="flex align_items_center">
              <div class="font-15">累计双百：</div>
              <div class="font-15">{{ item.totalDoubleHundredStation }}</div>
            </div>
          </div>
          <!--时点余额-->
          <div
            class="flex justify_content_space_around"
            v-if="tabType === 'PointBalance'"
          >
            <div class="flex align_items_center">
              <div class="font-15">新增余额：</div>
              <div class="font-15">{{ item.newlyPointBalance }}</div>
            </div>
            <div class="flex align_items_center">
              <div class="font-15">累计余额：</div>
              <div class="font-15">{{ item.totalPointBalance }}</div>
            </div>
          </div>
          <!--上线-->
          <div
                  class="flex justify_content_space_around"
                  v-if="tabType === 'onlineStation'"
          >
            <div class="flex align_items_center">
              <div class="font-15">新增上线：</div>
              <div class="font-15">{{ item.newlyOnlineCount }}</div>
            </div>
            <div class="flex align_items_center">
              <div class="font-15">累计上线：</div>
              <div class="font-15">{{ item.onlineCount }}</div>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "hasTitleGoals",
  props: {
    dataList: Array,
    tabType: String,
    rankingType:String
  },
  data() {
    return {
      bgBox:{
        backgroundImage: "url(" + require('../assets/img/ps/rank-bg.png') + ")",
      },
      gold:require('../assets/img/ps/gold.png'),
      silver:require('../assets/img/ps/silver.png'),
      copper:require('../assets/img/ps/copper.png'),
    };
  },
};
</script>

<style lang="less">
@import "../style/goals.less";
</style>
